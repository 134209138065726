<template>
  <div class="home-container">
    <!-- 首页轮播区 -->
    <el-carousel
      class="home-swiper"
      :interval="5000"
      arrow="hover"
      trigger="click"
      :height="swiperHeight + 'px'"
      :loop="true"
    >
      <el-carousel-item
        v-for="(swiper, index) in swipers"
        :key="index"
        class="swiper-item"
      >
        <img
          class="swiper-item-img"
          :src="swiper.img"
        >
      </el-carousel-item>
    </el-carousel>
    <!-- 首页产品方案 -->
    <div class="home-product">
      <div class="home-product-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">产品方案</span>
          <span class="title-content-cn">Solutions</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <!-- 产品方案列表 -->
      <div class="home-product-list">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="product-list-item"
        >
          <svg
            class="icon list-item-icon"
            aria-hidden="true"
          >
            <use :xlink:href="product.icon" />
          </svg>
          <div class="list-item-text">
            <span>{{ product.title }}</span>
            <span>{{ product.tips }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="home-news">
      <div class="home-news-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">新闻资讯</span>
          <span class="title-content-cn">News</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <!-- 新闻资讯列表 -->
      <div class="home-news-content">
        <div class="news-content-box">
          <ul
            class="content-box-ul"
            :style="'left: ' + left + 'px;width: ' + 600 * news.length + 'px'"
          >
            <li
              v-for="(item, index) in news"
              :key="index"
              class="content-box-li"
              :style="'left: ' + index * 600 + 'px'"
            >
              <!-- type: 1站内新闻 -->
              <router-link
                v-if="item.type === 1"
                class="box-li-item"
                :to="{ name: 'NewsDetail', params: { id: item.id }}"
                target="_blank"
              >
                <div class="li-item-img">
                  <div>
                    <img
                      :src="item.cover"
                      alt=""
                    >
                  </div>
                </div>
                <div class="li-item-main">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.digest }}</p>
                  <span>阅读全文</span>
                </div>
              </router-link>
              <!-- type: 2站外新闻 -->
              <a
                v-if="item.type === 2"
                class="box-li-item"
                :href="item.link"
                target="_blank"
              >
                <div class="li-item-img">
                  <div><img
                    :src="item.cover"
                    alt=""
                  ></div>
                </div>
                <div class="li-item-main">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.digest }}</p>
                  <span>阅读全文</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="news-btns">
          <div class="news-content-btns">
            <div
              class="content-btns-item"
              :style="'opacity:' + leftOpacity"
              @click="previousPage"
            >
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan4" />
              </svg>
            </div>
            <div
              class="content-btns-item"
              @click="routerNews"
            >
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan5" />
              </svg>
            </div>
            <div
              class="content-btns-item"
              :style="'opacity:' + rightOpacity"
              @click="nextPage"
            >
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan6" />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="news-all"
          @click="routerNews"
        >
          显示全部
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="home-cooperate">
      <div class="home-cooperate-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">合作伙伴</span>
          <span class="title-content-cn">Partners</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <ul class="home-cooperate-list">
        <li
          v-for="(partner, index) in partners"
          v-if="index < partnerIndex"
          :key="index"
          class="cooperate-list-item"
          :title="partner.name"
        >
          <a
            :href="partner.link"
            target="_blank"
          >
            <img
              :src="partner.img"
              :alt="partner.name"
            >
          </a>
        </li>
      </ul>
      <div
        v-if="partnerIndex === 6"
        class="home-cooperate-all"
        @click="showMorePartners"
      >
        显示全部
      </div>
      <div
        v-if="partnerIndex !== 6"
        class="home-cooperate-all"
        @click="hideMorePartners"
      >
        部分隐藏
      </div>
    </div>
    <!-- 资质认证 -->
    <div class="home-prove">
      <div class="home-prove-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">资质认证</span>
          <span class="title-content-cn">Qualifications</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="home-prove-list">
        <div class="prove-list-item">
          <img src="../assets/Images/home-prove-1.jpg">
        </div>
        <div class="prove-list-item">
          <img src="../assets/Images/home-prove-2.jpg">
        </div>
        <div class="prove-list-item">
          <img src="../assets/Images/home-prove-3.jpg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      swipers: [],
      clientWidth: 0,
      swiperHeight: '',
      // 产品数据
      products: [
        { icon: '#icon-ziyuan', title: '培训', tips: '人力资源培训' },
        { icon: '#icon-ziyuan2', title: '外包', tips: '人力资源服务外包' },
        { icon: '#icon-ziyuan3', title: 'IT产品', tips: '人力资源IT产品' },
        { icon: '#icon-ziyuan1', title: '孵化', tips: '劳务公司孵化' }
      ],
      // 新闻资讯
      news: [],
      left: 0,
      leftOpacity: 0.5,
      rightOpacity: 1,
      // 合作伙伴
      partners: [],
      // 移动端显示更多合作伙伴
      partnerIndex: 6
    }
  },
  mounted () {
    // 获取新闻列表
    this.getNews()
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    // 获取合作伙伴列表
    this.getPartners()
    // 轮播图
    this.getSwipers()
    // 监听浏览器窗口的变化
    window.addEventListener('resize', () => {
      if (document.querySelectorAll('.swiper-item-img')[0]) {
        this.swiperHeight = document.querySelectorAll('.swiper-item-img')[0].height.toString()
      }
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      if (this.clientWidth < 700) {
        this.partnerIndex = 6
      } else {
        this.partnerIndex = this.partners.length
      }
    })
  },
  methods: {
    // 上一页
    previousPage () {
      if (this.leftOpacity === 1) {
        this.left = this.left + 1200
        this.rightOpacity = 1
        if (this.left >= 0) {
          this.leftOpacity = 0.5
        }
      }
    },
    // 下一页
    nextPage () {
      if (this.rightOpacity === 1) {
        this.left = this.left - 1200
        this.leftOpacity = 1
        if (Math.abs(this.left) + 1200 >= 600 * this.news.length) {
          this.rightOpacity = 0.5
        }
      }
    },
    // 路由到新闻资讯
    routerNews () {
      this.$router.push({ name: 'News' })
    },
    // 轮播图swipers
    getSwipers () {
      this.$store.dispatch('getSwipers').then(res => {
        res.forEach(item => {
          this.swipers.push(item)
        })
        setTimeout(() => {
          this.swiperHeight = document.querySelectorAll('.swiper-item-img')[0].height.toString()
        }, 500)
      })
    },
    // 获取新闻列表
    getNews () {
      this.$store.dispatch('getNews').then(res => {
        if (res.data.length > 0) {
          res.data.forEach(item => {
            this.news.push(item)
          })
          // 控制新闻列表的左右按钮是否可以点击
          if (Math.abs(this.left) + 1200 >= 600 * this.news.length) {
            this.rightOpacity = 0.5
          }
        }
      })
    },
    // 获取合作伙伴列表
    getPartners () {
      this.$store.dispatch('getPartners').then(res => {
        res.forEach(item => {
          this.partners.push(item)
        })
        if (this.clientWidth < 700) {
          this.partnerIndex = 6
        } else {
          this.partnerIndex = this.partners.length
        }
      })
    },
    // 显示更多合作伙伴
    showMorePartners () {
      this.partnerIndex = this.partners.length
    },
    // 隐藏更多合作伙伴
    hideMorePartners () {
      this.partnerIndex = 6
    }
  }
}
</script>
<style lang="less" scoped>
.home-container {
  width: 100%;
  /* 广告区 */
  .home-swiper {
    width: 100%;
    .swiper-item {
      width: 100%;
      .swiper-item-img {
        width: 100%;
        // height: 500px;
      }
    }
  }
  /* 产品方案 */
  .home-product {
    .home-product-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      box-sizing: border-box;
      .list-item-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          &:first-child {
            color: @global-active-text-color;
            letter-spacing: 2px;
          }
          &:last-child {
            color: @global-text-color;
            letter-spacing: 1px;
            margin-top: 4px;
          }
        }
      }
    }
  }
  /* 新闻资讯 */
  .home-news {
    .home-news-content {
      width: 100%;
      box-sizing: border-box;
      box-sizing: border-box;
      background-image: url("../assets/Images/home-news-bg.jpg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      /* background-size: 100% 100%; */
      /* 新闻列表 */
      .news-content-box {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        margin-top: 20px;
        box-sizing: border-box;
        .content-box-ul {
          height: 100%;
          .content-box-li {
            background: #fff;
            .box-li-item {
              width: 100%;
              height: 100%;
              display: flex;
              .li-item-main {
                text-align: left;
                position: relative;
                box-sizing: border-box;
                h3 {
                  color: @global-title-color;
                  letter-spacing: 1px;
                }
                p {
                  color: @global-text-color;
                  letter-spacing: 1px;
                  margin-top: -15px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                span {
                  display: block;
                  color: @global-title-color;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      /* 筛选按钮 */
      .news-content-btns {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        .content-btns-item {
          .icon {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
          &:nth-child(2) {
            margin: 0 12px;
            .icon {
              width: 67px;
              height: 100px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  /* 合作伙伴 */
  .home-cooperate {
    .home-cooperate-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      .cooperate-list-item {
        height: 100px;
        a {
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.1s linear 0s;
          img {
            width: 70%;
            
            // height: 42px;
          }
          &:hover{
            transform: scale(1.1);
          }
        }
      }
    }
  }
  /* 资质认证 */
  .home-prove-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 30px;
    .prove-list-item {
      // cursor: pointer;
      // margin-right: 40px;
      // width: 30%;
      img {
        // width: 278px;
        // width: 100%;
        // height: 260px;
        transition: all 0.1s linear 0s;
      }
      img:hover{
        transform: scale(1.1);
      }
      // &:hover {
      //   transform: scale(1.5);
      // }
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  /* 产品方案 */
  .home-product-list {
    justify-content: space-around;
    padding: 20px 200px;
    .product-list-item {
      width: 25%;
      .list-item-icon {
        width: 110px;
        height: 110px;
      }
      .list-item-text {
        margin-top: 13px;
        span {
          &:first-child {
            font-size: 25px;
          }
          &:last-child {
            font-size: 16px;
          }
        }
      }
    }
  }
  /* 新闻资讯 */
  .home-news {
    margin-top: 20px;
    .home-news-content {
      height: 400px;
      padding-top: 40px;
      .news-content-box {
        width: 1200px;
        height: 230px;
        padding: 40px 0;
        .content-box-ul {
          display: flex;
          position: absolute;
          top: 0;
          transition: left 1.2s linear;
          .content-box-li {
            width: 585px !important;
            height: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            .box-li-item {
              .li-item-img {
                width: 170px;
                height: 170px;
                margin-top: 20px;
                div {
                  width: 170px;
                  height: 170px;
                  img {
                    width: 170px;
                    height: 170px;
                  }
                }
              }
              .li-item-main {
                margin-left: 20px;
                margin-right: 15px;
                margin-top: 10px;
                h3 {
                  font-size: 17px;
                  line-height: 27px;
                  font-weight: normal;
                }
                p {
                  font-size: 15px;
                  line-height: 23px;
                  -webkit-line-clamp: 4;
                }
                span {
                  position: absolute;
                  bottom: 32px;
                  right: 5px;
                }
              }
            }
          }
        }
      }
      .news-btns {
        display: block;
      }
      .news-all {
        display: none;
      }
    }
  }
  /* 合作伙伴 */
  .home-cooperate-list {
    width: 1200px;
    margin: 30px auto;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    .cooperate-list-item {
      width: 12.3%;
      border-right: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  .home-cooperate-all {
    display: none;
  }
  /* 资质认证 */
  .home-prove-list {
    width: 1200px;
    padding-bottom: 80px;
    .prove-list-item {
      width: 30%;
      img {
        height: 260px;
      }
    }
  }
}
/* -----------适应pc端(中屏幕)----------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  /* 产品方案 */
  .home-product-list {
    justify-content: space-between;
    padding: 20px 80px;
    .product-list-item {
      width: 25%;
      margin-bottom: 20px;
      .list-item-icon {
        width: 100px;
        height: 100px;
      }
      .list-item-text {
        margin-top: 10px;
        span {
          &:first-child {
            font-size: 23px;
          }
          &:last-child {
            font-size: 15px;
          }
        }
      }
    }
  }
  /* 新闻资讯 */
  .home-news {
    .home-news-content {
      height: 400px;
      padding-top: 40px;
      .news-content-box {
        width: 100%;
        height: 230px;
        padding: 40px 0;
        .content-box-ul {
          display: flex;
          position: absolute;
          top: 0;
          transition: left 1.2s linear;
          .content-box-li {
            width: 585px !important;
            height: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            .box-li-item {
              .li-item-img {
                width: 170px;
                height: 170px;
                margin-top: 20px;
                div {
                  width: 170px;
                  height: 170px;
                  img {
                    width: 170px;
                    height: 170px;
                  }
                }
              }
              .li-item-main {
                margin-left: 20px;
                margin-right: 15px;
                margin-top: 10px;
                h3 {
                  font-size: 17px;
                  line-height: 27px;
                  font-weight: normal;
                }
                p {
                  font-size: 15px;
                  line-height: 23px;
                  -webkit-line-clamp: 4;
                }
                span {
                  position: absolute;
                  bottom: 32px;
                  right: 5px;
                }
              }
            }
          }
        }
      }
      .news-btns {
        display: block;
      }
      .news-all {
        display: none;
      }
    }
  }
  /* 合作伙伴 */
  .home-cooperate-list {
    width: 100%;
    margin: 30px auto;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    .cooperate-list-item {
      width: 12.3%;
      border-right: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  .home-cooperate-all {
    display: none;
  }
  /* 资质认证 */
  .home-prove-list {
    width: 1200px;
    padding-bottom: 80px;
    .prove-list-item {
      width: 30%;
      img {
        width: 260px;
      }
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  /* 产品方案 */
  .home-product-list {
    justify-content: space-between;
    padding: 0 30px;
    .product-list-item {
      width: 50%;
      margin-bottom: 15px;
      .list-item-icon {
        width: 60px;
        height: 60px;
      }
      .list-item-text {
        margin-top: 3px;
        span {
          &:first-child {
            font-size: 16px;
          }
          &:last-child {
            font-size: 12px;
          }
        }
      }
    }
  }
  /* 新闻资讯 */
  .home-news {
    .home-news-content {
      height: 420px;
      .news-content-box {
        width: 100%;
        height: 380px;
        padding: 0;
        .content-box-ul {
          width: 100% !important;
          margin: 0;
          padding: 0;
          .content-box-li {
            width: 94%;
            margin-left: 3%;
            margin-top: 15px;
            height: 170px;
            .box-li-item {
              .li-item-img {
                width: 110px;
                height: 110px;
                margin-top: 20px;
                div {
                  width: 110px;
                  height: 110px;
                  img {
                    width: 110px;
                    height: 110px;
                  }
                }
              }
              .li-item-main {
                margin-left: 15px;
                margin-right: 8px;
                h3 {
                  font-size: 15px;
                  line-height: 27px;
                  font-weight: bold;
                  width: 210px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                p {
                  font-size: 13px;
                  line-height: 20px;
                  -webkit-line-clamp: 3;
                  padding-right: 5px;
                  box-sizing: border-box;
                }
                span {
                  position: absolute;
                  bottom: 22px;
                  right: 10px;
                }
              }
            }
          }
        }
      }
      .news-btns {
        display: none;
      }
      .news-all {
        display: block;
        color: #fff;
        padding: 8px 0 15px 0;
        box-sizing: border-box;
        letter-spacing: 1px;
      }
    }
  }
  /* 合作伙伴 */
  .home-cooperate-list {
    width: 100%;
    margin: 10px auto 0;
    .cooperate-list-item {
      width: 40%;
      margin-left: 4%;
      margin-right: 4%;
      margin-bottom: 15px;
      border: 1px solid #e6e6e6;
    }
  }
  .home-cooperate-all {
    display: block;
    color: #666;
    padding: 12px 0;
    letter-spacing: 1px;
  }
  /* 资质认证 */
  .home-prove-list {
    width: 100%;
    padding: 0 30px 50px 30px;
    box-sizing: border-box;
    .prove-list-item {
      width: 100%;
      margin-bottom: 10px;
      img {
        // width: 100%;
        height: 200px;
      }
    }
  }
}
</style>
